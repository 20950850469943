.chip {
  background-color: #f5f5f5;
  color: #bd4147;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 1px 3px 0 rgba(0, 0, 0, 0.12);
  display: inline-block;
  border-radius: 3px;
  white-space: pre-wrap;
  font-size: 85%;
  font-weight: 900;
  padding: 0 10px;
}

.introduction {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.chart-container {
  .sub-heading {
    font-size: 16px !important;
    font-weight: 400;
    margin-top: 10px;
  }

  .chart-discription {
    font-size: 14px;
    margin: 10px 0;
    font-weight: 500;
  }

  .chart-box {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    background: #fafafa;
    padding: 30px 0;
    background: white;
  }
}
