@mixin ele-font($name) {
  font-family: $name !important;
}

.background-transparent {
  background: transparent !important;
}

.break-space-white {
  white-space: break-spaces !important;
}

@for $i from 0 through 1000 {
  .border-radius-#{$i} {
    border-radius: $i * 1px !important;
  }

  .fs-#{$i} {
    font-size: $i * 1px !important;

    &.light-text {
      @include ele-font("muli-light");
    }

    &.regular-text {
      @include ele-font("muli-regular");
    }

    &.medium-text {
      @include ele-font("muli-medium");
    }

    &.medium-text-copy {
      @include ele-font("muli-medium");
    }

    &.demi-bold-text {
      @include ele-font("muli-semi-bold");
    }

    &.bold-text {
      @include ele-font("muli-bold");
    }
  }

  .wa-#{$i} {
    width: $i * 10px !important;
  }

  .ha-#{$i} {
    height: $i * 10px !important;
  }

  .pa-#{$i} {
    padding: $i * 1px !important;
  }

  .pr-#{$i} {
    padding-right: $i * 1px !important;
  }

  .pl-#{$i} {
    padding-left: $i * 1px !important;
  }

  .pt-#{$i} {
    padding-top: $i * 1px !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * 1px !important;
  }

  .plr-#{$i} {
    padding-left: $i * 1px !important;
    padding-right: $i * 1px !important;
  }

  .prl-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }

  .ptb-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }

  .pbt-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }

  .pleft-#{$i} {
    padding-left: $i * 1px !important;
  }

  .pright-#{$i} {
    padding-right: $i * 1px !important;
  }

  .ptop-#{$i} {
    padding-top: $i * 1px !important;
  }

  .pbottom-#{$i} {
    padding-bottom: $i * 1px !important;
  }

  .ma-#{$i} {
    margin: $i * 1px !important;
  }

  .mr-#{$i} {
    margin-right: $i * 1px !important;
  }

  .mt-#{$i} {
    margin-top: $i * 1px !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * 1px !important;
  }

  .ml-#{$i} {
    margin-left: $i * 1px !important;
  }

  .mright-#{$i} {
    margin-right: $i * 1px !important;
  }

  .mtop-#{$i} {
    margin-top: $i * 1px !important;
  }

  .mbottom-#{$i} {
    margin-bottom: $i * 1px !important;
  }

  .mleft-#{$i} {
    margin-left: $i * 1px !important;
  }

  .mlr-#{$i} {
    margin-left: $i * 1px !important;
    margin-right: $i * 1px !important;
  }

  .mrl-#{$i} {
    margin-left: $i * 1px !important;
    margin-right: $i * 1px !important;
  }

  .mtb-#{$i} {
    margin-top: $i * 1px !important;
    margin-bottom: $i * 1px !important;
  }

  .mbt-#{$i} {
    margin-top: $i * 1px !important;
    margin-bottom: $i * 1px !important;
  }

  .max-w-#{$i} {
    max-width: $i * 1px !important;
  }

  .max-h-#{$i} {
    max-height: $i * 1px !important;
  }

  .min-w-#{$i} {
    min-width: $i * 1px !important;
  }

  .min-h-#{$i} {
    min-height: $i * 1px !important;
  }
}

.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.text-center {
  text-align: center !important;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.headline {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: normal;
}

.font-bold {
  font-weight: 600;
}

.pull-right {
  float: right;
}

.border-none {
  border: 0 !important;
}

.bg-green {
  background-color: #aece38;
}

.border-dark {
  border: 1px solid #58585a;
}

.color-white {
  color: #fff;
}

.vert-middle {
  vertical-align: middle;
}

.flex {
  display: flex;

  &.center {
    align-items: center;
  }
}

.flex-x {
  display: flex;
  flex-direction: row;

  &.align-center {
    align-items: center;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}

.flex-y {
  display: flex;
  flex-direction: column;

  &.center {
    align-items: center;
    justify-content: center;
  }
}

.space-between {
  justify-content: space-between;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.full-height {
  height: 100vh;
}

.fill-width {
  width: 100% !important;
}

.fill-height {
  height: 100% !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hide {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.with-transition {
  transition: all 0.3s ease-in;
}

.hover-border {
  &:hover {
    text-decoration: underline;
  }
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.align-base {
  align-items: baseline;
}

.tick-bg-color {
  font-size: 2rem;
  color: green;
  background-color: #0080002b;
  border-radius: 50%;
}

.tick-no-bg-color {
  font-size: 2rem;
  border-radius: 50%;
}

.cross-bg-color {
  font-size: 2rem;
  border-radius: 50%;
  color: #ef4444;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.custom-heading-title {
  font-size: larger;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  letter-spacing: 1px;
  font-family: muli-bold;
}

.flex-2 {
  flex: 2;
}

.visit-note-drag-main {
  position: absolute;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.visit-note-drag-rel {
  position: relative;
  width: 100vw;
  height: 100vh;
  border: 1px solid #ccc;
  overflow: hidden;
  z-index: 99999;
}

.visit-note-resize {
  width: 10px;
  height: 10px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.visit-note-fixed-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.visit-note-header:hover {
  background-color: #f9fafb;
  overflow-y: auto !important;
}

.cursor-drag {
  cursor: move !important;
}

.bg-slate {
  background-color: #f1f5f9;
}

.resize-none {
  resize: none !important;
}

.color-setting-error {
  color: #df5151;
}

.diagnose-code-header {
  font-size: 18px !important;
  color: #334155 !important;
  font-weight: 700 !important;
}

.color-husk-green {
  color: #aece38 !important;
}

.view-label {
  font-weight: 500;
  text-align: left;
  padding-right: 8px;
  font-size: 18px;
  min-width: fit-content;
}

.rgap {
  row-gap: 8px;
}
